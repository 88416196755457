import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="footer-bg">
            <footer>
              <div className="container-fluid">
                <div className="row">
                  <div className="gap"></div>
                </div>
              </div>
              <div className="container thanks-footer">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="mb20">
                      <p className="mb5 terms-txt">
                        Terms of Service | Privacy Policy
                      </p>
                      <p> &copy; 2017 Urgent.ly, All rights reserved</p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
