import React, { Component } from "react";
import { GA_EVENT_TIMEOUT } from 'utility/api-config'

let config = { send_page_view: false, cookie_flags: "Secure" };
// { send_page_view : false } stops GA script from sending a page view automatically on first load
// We need to avoid sending page view automatically from GA script because of SPA

export const setTrackingConfig = (params) => {
  // The params that are set here other than send_page_view will be sent with all the events
  config = { ...config, ...params };
  if (window.gtag) {
    window.gtag("config", process.env.REACT_APP_GTAG_ID, config);
  }
};

export const setDefaultTrackingInfo = ( params ) => {
  // console.log( 'setDefaultTrackingInfo: ', params );
  setTrackingConfig({
    user_type: params.entityType,
    provider_uid: params.providerUid,
    entity_uid: params.entityUid,
    state: params.state,
  });
};

export const trackPage = (params) => {
  // console.log('trackPage() called: ', params);
  if (window.gtag) {
    window.gtag("event", "page_view", params);
  }
};

export const trackEvent = (name, params) => {
  // console.log(`trackEvent() called: name: ${name}, params: `, params);
  if (window.gtag) {
    window.gtag("event", name, params);
  }
};

export const withTracker = (WrappedComponent, options = {}) => {

  const track = (pathname, searchParams) => {

    const page_title = ( pathname === "/" || pathname === "/dispatch" )  ? "landing-screen" : pathname?.replace("/", "");

    trackPage({
      page_title,
      page_path: pathname,
      page_location: pathname + searchParams
    });

  };

  const HOC = class extends Component {

    componentDidMount() {
      const { pathname, search } = this.props.location;
      if (typeof window.gtag === "undefined" ) {
        setTimeout( () => {
          track(pathname, search)
        }, GA_EVENT_TIMEOUT );
      } else {
        track(pathname, search)
      }
    }

    componentDidUpdate( prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const { pathname, search } = this.props.location;
      const nextPage = pathname + search;

      if (currentPage !== nextPage) {
        track(pathname, search);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};


export const Events = {
  BG_REGISTRATION_REQUESTED: 'BG_REGISTRATION_REQUESTED',
  BG_REGISTRATION_SUCCESS: 'BG_REGISTRATION_SUCCESS',
  BG_REGISTRATION_FAILED: 'BG_REGISTRATION_FAILED',
  BG_REGISTRATION_ALREADY_COMPLETED: 'BG_REGISTRATION_ALREADY_COMPLETED', // If Candidate is already created.
  BG_EMAILID_ALREADY_REGISTERED: 'BG_EMAILID_ALREADY_REGISTERED', // If provided emailId is not unique 
}
