import React, { Component } from 'react'
import Logo from '../../assets/img/urgently-logo-orange.svg'

class Header extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="logo-section">
            <div className="col-sm-12">
              <img src={Logo} alt="img" className="img-fluid" />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
