import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createHashHistory as createHistory } from 'history'
import * as serviceWorker from './serviceWorker'

import './assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './components/Header'
import Footer from './components/Footer'

import BackgroundCheck from './views/BackgroundCheck/BackgroundCheck'
import ThankYou from './views/ThankYou/ThankYou'
import { withTracker } from './utility/analytics'

const history = createHistory()

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <div className="container-fluid main-content">
            <div className="row">
              <div className="container">
                <Header />
                <Switch>
                  <Route exact path="/dispatch" component={ process.env.REACT_APP_GTAG_ID ? withTracker( BackgroundCheck ) : BackgroundCheck } />
                  <Route exact path="/thank-you" component={ process.env.REACT_APP_GTAG_ID ? withTracker( ThankYou ) : ThankYou } />
                </Switch>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
