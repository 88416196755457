let backendHost
const apiVersion = 'background-verification-service-v1/'

const hostname = window && window.location && window.location.hostname

if (/^dev/.test(hostname) || /^localhost/.test(hostname)) {
  backendHost = 'https://dev-api-v2.urgent.ly/'
} else if (/^qa/.test(hostname)) {
  backendHost = 'https://qa-api-v2.urgent.ly/'
} else {
  backendHost = `https://api-v2.urgent.ly/`
}

export const API_ROOT = `${backendHost}${apiVersion}`

export const BACKGROUNDCHECK_CANDIDATE_API = `backgroundcheck/candidate`;
export const BACKGROUNDCHECK_INVITATION_API = `backgroundcheck/invitation`;


export const GA_EVENT_TIMEOUT = 5000;
