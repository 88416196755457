import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const Popup = props => {
  return (
    <>
      <Modal
        data-testid="model"
        show={props.display}
        onHide={props.onHide}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title data-testid="model_title">Background Check</Modal.Title>
        </Modal.Header>
        <Modal.Body data-testid="model_body">{props.body}</Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="model_btn_ok"
            variant="secondary"
            onClick={props.onHide}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Popup
