import React from 'react'

class ThankYou extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-7 col-sm-12 mx-auto">
          <div className="content-section thanks-content">
            <h2>We’re sending you an email with next steps.</h2>
            <p className="mb15">
              You will receive an email from Checkr, our background check
              partner.
            </p>
            <p>
              If you don't see an email in the next few minutes, please check
              your spam folder.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ThankYou
