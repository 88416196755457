import React from 'react'
import API from 'utility/api'
import { withRouter } from 'react-router-dom'
import Loader from 'components/Loader'
import Popup from 'components/Popup'
import { BACKGROUNDCHECK_CANDIDATE_API, BACKGROUNDCHECK_INVITATION_API, GA_EVENT_TIMEOUT } from 'utility/api-config'
import { Events, setDefaultTrackingInfo, trackEvent } from 'utility/analytics'

class BackgroundCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      firstName: '',
      middleName: '',
      noMiddleName: false,
      lastName: '',
      email: '',
      terms: false,
      enroll: false,
      notificationData: null,
      errors: {
        firstName: '',
        middleName: '',
        noMiddleName: false,
        lastName: '',
        email: '',
        terms: false,
        enroll: false,
      },
    }
    this.queryParams = {}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
    this.isEnvironmentProd = this.isEnvironmentProd(this)
  }

  isEnvironmentProd() {
    const hostname = window && window.location && window.location.hostname;
    // console.log(window.location.hostname);
    let isProd = true
    if (/^dev/.test(hostname) || /^localhost/.test(hostname)) {
      isProd = false
    } else if (/^qa/.test(hostname)) {
      isProd = false
    } else {
      isProd = true
    }
    return isProd
  }

  componentDidMount() {
    // console.log(this.props.location)
    this.queryParams = this.parseQueryString(this.props.location.search)
    console.log(this.queryParams);

    if (typeof window.gtag === "undefined" ) {
      setTimeout(() => {
        setDefaultTrackingInfo(this.queryParams);
      }, GA_EVENT_TIMEOUT);
    } else {
      setDefaultTrackingInfo(this.queryParams);
    }
  }

  getEventParams  = () => {
    return {
      driver_uid: this.queryParams.entityUid,
      provider_uid: this.queryParams.providerUid,
      first_name: this.state.firstName,
      middle_name: this.state.middleName,
      last_name: this.state.lastName,
      email: this.state.email,
      state: this.queryParams.state && this.queryParams.state !== 'null'
        ? this.queryParams.state
        : '',
    }
  }

  handleChange(event) {
    const { target } = event
    const { name, type } = event.target
    const value = type === 'checkbox' ? target.checked : target.value.trim()
    this.setState({ [name]: value })
    if (name === 'middleName' && value !== '') {
      this.setState({ noMiddleName: false })
    }
    if (name === 'noMiddleName' && value === true) {
      this.setState({
        middleName: '',
        errors: { ...this.state.errors, middleName: '' },
      })
    }
    this.validateInput(name, type, value)
  }

  async handleSubmit(event) {
    console.log('handleSubmit called')
    event.preventDefault()
    const driverData = {
      first_name: this.state.firstName,
      middle_name: this.state.middleName,
      last_name: this.state.lastName,
      email: this.state.email,
      state:
        this.queryParams.state && this.queryParams.state !== 'null'
          ? this.queryParams.state
          : '',
      provideruid: this.queryParams.providerUid,
      driveruid: this.queryParams.entityUid,
    }
    /*isContinuousCheckAllowed: !(
        this.queryParams.state &&
        (this.queryParams.state.toLowerCase() === 'ca' ||
          this.queryParams.state.toLowerCase() === 'california')
    ),*/

    API.AUTH_TOKEN = this.queryParams.accessToken

    this.setState({ loading: true })
    console.log('DriverParams: ', driverData)
    trackEvent(Events.BG_REGISTRATION_REQUESTED,  this.getEventParams() );
    API.POST( BACKGROUNDCHECK_CANDIDATE_API, driverData)
      .then(res => {
        console.log('backgroundcheck/candidate : API : success :')
        console.log('Response :', res)
        if (res?.data?.data?.checkrId && res.data.data.checkrId !== '') {
          this.initiateInvitationAPICall(res, driverData)
        } else {

          if ( res.data?.errorResponseDTO?.message && res.data.errorResponseDTO.message.indexOf('Candidate is already created') !== -1 ) {

            trackEvent(Events.BG_REGISTRATION_ALREADY_COMPLETED, { ...this.getEventParams(), message: res.data.errorResponseDTO.message } );
          } else if ( res.data?.errorResponseDTO?.message && res.data.errorResponseDTO.message.indexOf('Provided emailId is not unique') !== -1 ) {

            trackEvent(Events.BG_EMAILID_ALREADY_REGISTERED, { ...this.getEventParams(), message: res.data.errorResponseDTO.message } );
          } else {
            const eventParams = this.getEventParams();
            if ( res.data?.errorResponseDTO?.message ) {
              eventParams.message = res.data?.errorResponseDTO?.message;
            }
            trackEvent(Events.BG_REGISTRATION_FAILED, eventParams );
          }

          let header = ''
          let body = 'Oops! Something went wrong. Please try again.'

          if (
            res.data?.errorResponseDTO?.code &&
            res.data?.errorResponseDTO?.message
          ) {
            header = res.data.errorResponseDTO.code
            body = res.data.errorResponseDTO.message
          }
          const msg = {
            header: header,
            body: body,
            display: true,
          }
          this.setState({ loading: false, notificationData: msg })
        }
      })
      .catch(e => {
        trackEvent(Events.BG_REGISTRATION_FAILED, { ...this.getEventParams(), message: e.toString() } );
        console.log('backgroundcheck/candidate : API : fail :')
        console.error(e)
        console.log(e.toString())
        const msg = {
          header: '',
          body: 'Oops! Something went wrong. Please try again.',
          display: true,
        }
        this.setState({ loading: false, notificationData: msg })
      })
  }

  goToThankYouScreen = () => {
    this.setState({ loading: false })
    this.props.history.push('thank-you')
  }

  initiateInvitationAPICall(candidateRes, driverData) {
    const checkrId = candidateRes.data.data.checkrId
    const invitationData = {
      candidate_id: checkrId,
    }
    console.log( 'InvitationParams: ', invitationData );
    API.POST( BACKGROUNDCHECK_INVITATION_API , invitationData )
      .then( invitationRes => {
        console.log('backgroundcheck/invitation : API : success :')
        console.log('Response :', invitationRes);

        if (invitationRes.data?.data?.id) {
          trackEvent(Events.BG_REGISTRATION_SUCCESS, { ...this.getEventParams(), checkr_id: checkrId } );
          this.goToThankYouScreen()
          /*if (this.isEnvironmentProd && driverData.isContinuousCheckAllowed) {
            this.initiateSubscriberAPICall(candidateRes, driverData)
          } else {
            this.goToThankYouScreen()
          }*/
        } else {
          const eventParams = this.getEventParams();
          let header = ''
          let body = 'Oops! Something went wrong. Please try again.'
          if (
            invitationRes.data?.errorResponseDTO?.code &&
            invitationRes.data?.errorResponseDTO?.message
          ) {
            header = invitationRes.data.errorResponseDTO.code
            body = invitationRes.data.errorResponseDTO.message
            eventParams.message = body;
          }
          const msg = {
            header: header,
            body: body,
            display: true,
          }
          this.setState({ loading: false, notificationData: msg });
          trackEvent(Events.BG_REGISTRATION_FAILED,  eventParams );
        }
      })
      .catch(e => {
        trackEvent(Events.BG_REGISTRATION_FAILED, { ...this.getEventParams(), message: e.toString() } );
        console.log('backgroundcheck/invitation : API : fail :')
        console.error(e)
        console.log(e.toString())
        const msg = {
          header: '',
          body: 'Oops! Something went wrong. Please try again.',
          display: true,
        }
        this.setState({ loading: false, notificationData: msg })
      })
  }

  validateInput(name, type, value) {
    let errors = this.state.errors
    switch (name) {
      case 'firstName':
        if (value === '') {
          errors.firstName = 'First name is not valid.'
        } else {
          errors.firstName = ''
        }
        break
      case 'lastName':
        if (value === '') {
          errors.lastName = 'Last name is not valid.'
        } else {
          errors.lastName = ''
        }
        break
      case 'middleName':
        if (value === '' && this.state.noMiddleName === false) {
          errors.middleName = 'Middle name is not valid.'
        } else {
          errors.middleName = ''
        }
        break
      case 'email':
        if (value === '' || this.validateEmail(value) !== true) {
          errors.email = 'Email is not valid.'
        } else {
          errors.email = ''
        }
        break
      case 'terms':
        if (value === false) {
          errors.terms = 'Please accept terms and conditions.'
        } else {
          errors.terms = ''
        }
        break
      case 'enroll':
        if (value === false) {
          errors.enroll = 'Please accept enroll for background check.'
        } else {
          errors.enroll = ''
        }
        break
      default:
        break
    }
  }
  canBeSubmitted() {
    const {
      firstName,
      middleName,
      noMiddleName,
      lastName,
      email,
      terms,
      enroll,
    } = this.state

    this.validateInput()

    return (
      firstName.trim().length > 0 &&
      (middleName.trim().length > 0 || noMiddleName === true) &&
      lastName.trim().length > 0 &&
      email.trim().length > 0 &&
      this.validateEmail(email.trim()) &&
      terms === true &&
      enroll === true
    )
  }
  parseQueryString = string => {
    let parsed = {}
    if (string !== '') {
      string = string.substring(string.indexOf('?') + 1)
      let p1 = string.split('&')
      p1.map(function(value) {
        let params = value.split('=')
        parsed[params[0]] = params[1]
        return parsed
      })
    }
    return parsed
  }

  validateEmail(email) {
    const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
    if (emailRegex.test(email)) {
      return true
    }
    return false
  }
  hidePopup() {
    const msg = {
      header: '',
      body: '',
      display: false,
    }
    this.setState({ notificationData: msg })
  }
  render() {
    const {
      firstName,
      middleName,
      noMiddleName,
      lastName,
      email,
      terms,
      enroll,
    } = this.state
    const isEnabled = this.canBeSubmitted()
    return (
      <>
        {this.state.notificationData && this.state.notificationData.display && (
          <Popup {...this.state.notificationData} onHide={this.hidePopup} />
        )}
        <Loader loading={this.state.loading} />
        <div className="row">
          <div className="col-lg-7 col-sm-12 mx-auto">
            <div className="content-section">
              <h2>Welcome to our background check process.</h2>
              <p className="mb15">
                In compliance with our vendor requirements, and to take jobs
                with our platform, please fill in the following information to
                initiate your background check.
              </p>
              <p>
                Please submit your name and email address to start the process.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="form-section">
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <label htmlFor="">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    data-testid="firstName"
                    value={firstName}
                    onChange={this.handleChange}
                  />
                  <div className="notes">{this.state.errors.firstName}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Middle Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="middleName"
                    data-testid="middleName"
                    value={middleName}
                    disabled={noMiddleName}
                    onChange={this.handleChange}
                  />
                  <div className="notes">{this.state.errors.middleName}</div>
                </div>
                <div className="form-group text-left">
                  <label className="checkbox-area">
                    I don’t have a middle name.
                    <input
                      type="checkbox"
                      checked={noMiddleName}
                      name="noMiddleName"
                      data-testid="noMiddleName"
                      onChange={this.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    data-testid="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                  />
                  <div className="notes">{this.state.errors.lastName}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    data-testid="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  <div className="notes">{this.state.errors.email}</div>
                </div>
                <div className="form-group text-left">
                  <label className="checkbox-area">
                    I agree to all ULY terms and conditions.
                    <input
                      type="checkbox"
                      value={terms}
                      name="terms"
                      data-testid="terms"
                      onChange={this.handleChange}
                    />
                    <span className="checkmark"></span>
                    <div className="notes">{this.state.errors.terms}</div>
                  </label>
                </div>
                <div className="form-group text-left mb20">
                  <label className="checkbox-area">
                    I agree to enroll in a background check that will (outside
                    of the state of California be re-run regularly to ensure
                    compliance).
                    <input
                      type="checkbox"
                      value={enroll}
                      name="enroll"
                      data-testid="enroll"
                      onChange={this.handleChange}
                    />
                    <span className="checkmark"></span>
                    <div className="notes">{this.state.errors.enroll}</div>
                  </label>
                </div>
                <div className="form-group mb25">
                  <button
                    type="submit"
                    data-testid="submitBtn"
                    className="btn btn-primary"
                    disabled={!isEnabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(BackgroundCheck)
